import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import ContentPage from '../features/content-page/content-page';
import Layout from '../components/layout';
import SEO from '../components/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import { PageTemplateQueryQuery } from '../generated/graphql-types';

interface Props {
  data: PageTemplateQueryQuery;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
}

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      # seo {
      #   meta_description
      #   seo_title
      # }
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        image {
          asset {
            fluid(maxWidth: 1170) {
              ...GatsbySanityImageFluid
            }
          }
        }
        alt
        caption
      }
    }
  }
`;

export default function Post({ data, errors }: Props): ReactElement {
  return (
    <Layout>
      {errors && (
        <SEO title="GraphQL Error" description="something went wrong" />
      )}
      {errors && (
        <div>
          <GraphQLErrorList errors={errors} />
        </div>
      )}
      {data && data.page && (
        <>
          {/* {@TODO ADD SEO } */}
          <ContentPage
            title={data.page.title}
            publishedAt={data.page.publishedAt}
            _rawBody={data.page._rawBody}
            mainImage={data.page.mainImage}
          />
        </>
      )}
    </Layout>
  );
}
